import { makeStyles } from '@material-ui/core'
import { CalendarToday, Schedule } from '@material-ui/icons'
import React, { FC } from 'react'

import { MinistryTeam } from '@/models/MinistryTeam'

import { ButtonAnchor } from '../common/ButtonAnchor'

export const colors = ['#f5e0e3', '#eaefe3', '#e3eaef', '#ede6f6']

const MINISTRY_SIGNUP_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSdhV6Ehr3YUEQ7X65-eNYavDanmUFJWvxKaNog3g24U-tgI6w/viewform'

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: spacing(4, 0, 12),
    letterSpacing: '0.009375em',
    '&:nth-of-type(odd)': {
      flexDirection: 'row',
      '& $info': {
        marginLeft: spacing(-4),
        marginRight: 0,
        [breakpoints.down('xs')]: {
          marginLeft: spacing(-2),
          paddingRight: spacing(2),
        },
      },
    },
    '&:nth-of-type(even)': {
      flexDirection: 'row-reverse',
      '& $info': {
        marginLeft: 0,
        marginRight: spacing(-4),
        [breakpoints.down('xs')]: {
          marginRight: spacing(-2),
          paddingLeft: spacing(2),
        },
      },
    },
    '&:nth-of-type(4n+1) $info': {
      backgroundColor: colors[0],
    },
    '&:nth-of-type(4n+2) $info': {
      backgroundColor: colors[1],
    },
    '&:nth-of-type(4n+3) $info': {
      backgroundColor: colors[2],
    },
    '&:nth-of-type(4n+4) $info': {
      backgroundColor: colors[3],
    },
    [breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      margin: spacing(4, 0, 8),
    },
    [breakpoints.down('xs')]: {
      margin: spacing(4, 0, 6),
    },
  },
  photo: {
    position: 'relative',
    zIndex: 1,
    flex: 'none',
    height: 400,
    width: 400,
    objectFit: 'cover',
    [breakpoints.down('md')]: {
      height: 320,
      width: 320,
    },
    [breakpoints.down('sm')]: {
      height: 180,
      width: 180,
      marginTop: spacing(4),
    },
    [breakpoints.down('xs')]: {
      height: 120,
      width: 120,
      marginTop: spacing(2),
    },
  },
  info: {
    flex: 1,
    position: 'relative',
    zIndex: 0,
    padding: spacing(6, 16),
    [breakpoints.down('md')]: {
      padding: spacing(5, 12),
    },
    [breakpoints.down('sm')]: {
      padding: spacing(4, 8),
    },
    [breakpoints.down('xs')]: {
      padding: spacing(2, 4),
    },
  },
  title: {
    marginBottom: spacing(3),
    fontSize: '2rem',
    fontWeight: typography.fontWeightBold,
    [breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [breakpoints.down('sm')]: {
      marginBottom: spacing(2),
      fontSize: '1.5rem',
    },
    [breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
  description: {
    margin: spacing(0, 0, 4),
    fontSize: '1.125rem',
    lineHeight: 1.556,
    whiteSpace: 'pre-wrap',
    [breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [breakpoints.down('sm')]: {
      margin: spacing(0, 0, 3),
      fontSize: '0.875rem',
    },
    [breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(3),
    fontSize: '1.25rem',
    [breakpoints.down('md')]: {
      fontSize: '1.125rem',
    },
    [breakpoints.down('sm')]: {
      marginBottom: spacing(1.5),
      fontSize: '1rem',
    },
    [breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  },
  icon: {
    marginRight: spacing(2),
    fontSize: '1em',
    [breakpoints.down('sm')]: {
      marginRight: spacing(1),
    },
  },
  button: {
    margin: spacing(2, 0),
    [breakpoints.down('sm')]: {
      margin: spacing(1, 0),
    },
  },
}))

type MinistryTeamCardProps = {
  ministryTeam: MinistryTeam
}

export const MinistryTeamCard: FC<MinistryTeamCardProps> = ({
  ministryTeam,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img
        className={classes.photo}
        src={ministryTeam.photo_url ?? ''}
        alt={ministryTeam.name}
      />
      <div className={classes.info}>
        <div className={classes.title}>{ministryTeam.name}</div>
        <p className={classes.description}>{ministryTeam.description}</p>
        <div className={classes.row}>
          <CalendarToday className={classes.icon} />
          <span>{ministryTeam.days}</span>
        </div>
        <div className={classes.row}>
          <Schedule className={classes.icon} />
          <span>{ministryTeam.time}</span>
        </div>
        <ButtonAnchor
          href={MINISTRY_SIGNUP_LINK}
          target="_blank"
          className={classes.button}
        >
          Sign up
        </ButtonAnchor>
      </div>
    </div>
  )
}
