import React, { FC } from 'react'

import { PageContainer } from '@/components/common/PageContainer'
import { PageTitle } from '@/components/common/PageTitle'
import { MinistryTeam } from '@/models/MinistryTeam'

import { MinistryTeamCard } from './MinistryTeamCard'

type MinistryTeamListProps = {
  ministryTeams: MinistryTeam[]
}

export const MinistryTeamList: FC<MinistryTeamListProps> = ({
  ministryTeams,
}) => {
  return (
    <PageContainer>
      <PageTitle>Ministry Teams</PageTitle>
      {ministryTeams.map((ministryTeam) => (
        <MinistryTeamCard key={ministryTeam.name} ministryTeam={ministryTeam} />
      ))}
    </PageContainer>
  )
}
