import { Button, ButtonProps } from '@material-ui/core'
import React, { AnchorHTMLAttributes, FC } from 'react'

type ButtonAnchorProps = ButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>

export const ButtonAnchor: FC<ButtonAnchorProps> = (props) => {
  // TODO: remove after the ff. issue is fixed in material-ui
  // 'component' prop is not recognized by TypeScript
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Button component="a" {...props} />
}
