import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { ComponentPropsWithoutRef, FC } from 'react'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      margin: spacing(0, 0, 6),
      fontSize: '2.125rem',
      fontWeight: typography.fontWeightBold,
      letterSpacing: '0.036765em',
      color: palette.primary.main,
      textAlign: 'left',
      [breakpoints.down('sm')]: {
        fontSize: '1.375rem',
        letterSpacing: '0.03125em',
      },
      [breakpoints.down('xs')]: {
        fontSize: '1.125rem',
      },
    },
  })
)

type PageTitleProps = ComponentPropsWithoutRef<'div'>

export const PageTitle: FC<PageTitleProps> = ({
  className,
  children,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <h1 className={clsx(classes.root, className)} {...rest}>
      {children}
    </h1>
  )
}
