import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { PageCover } from '@/components/common/PageCover'
import { MinistryTeamList } from '@/components/next-steps/MinistryTeamList'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "join-team" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    ministryTeams: allStrapiMinistryTeams(
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        name
        description
        days
        time
        photo {
          localFile {
            url
          }
        }
        contact_id
      }
    }
  }
`

const JoinTeamPage: FC = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)

  return (
    <>
      <SEO title="Join a Team" />
      <PageCover image={image} title="Join a Team" />
      <MinistryTeamList
        ministryTeams={data.ministryTeams.nodes.map(({ photo, ...rest }) => ({
          ...rest,
          photo_url: photo?.localFile.url,
        }))}
      />
    </>
  )
}

export default JoinTeamPage
